import React, {useEffect, useState, memo, useCallback} from 'react';
import {Typography, Grid, Container, List} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {APICall} from '../api/api';
import {InventoryItem} from '../components/subcomponents/InventoryItem';

const GridIContainer = props => <Grid container {...props} />;

const useStyles = makeStyles(theme => ({
  wrapper: {
    flexGrow: 1,
    borderRadius: '4px',
    border: '1px solid #7979793b',
    marginTop: 20,
  },
  viewWrapper: {
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      paddingTop: 10,
    },
  },
}));

export const Inventory = memo(({login, domains}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(null);
  const [checked, setChecked] = useState([]);

  const getDomainData = async (domainId, force = null) => {
    let currentCMPstatus = await APICall({
      endpoint: 'getDataForDomain',
      params: domainId,
      options: {
        method: 'get',
        headers: {
          Authorization: `Bearer ${login.token}`,
          'Cache-Control': 'max-age=0, no-cache',
          'Content-Type': 'application/json',
        },
      },
    });

    return currentCMPstatus;
  };

  const getDataForAllDomains = useCallback(async () => {
    for (const domain of domains) {
      let domainData = await getDomainData(domain.id);

      // Filter tags where isTag = 1
      domainData = Object.keys(domainData).reduce((acc, key) => {
        if (domainData[key].isTag === 1) {
          acc.push(domainData[key]);
        }
        return acc;
      }, []);

      setChecked(prevChecked => ({
        ...prevChecked,
        [domain.id]: domainData,
      }));
    }
  }, [domains, login.token]);

  useEffect(() => {
    getDataForAllDomains();
  }, [getDataForAllDomains]);

  return (
    <Container component="main" maxWidth="xl" className={classes.viewWrapper}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={'h4'} gutterBottom>
            Inventory
          </Typography>

          <Typography gutterBottom>
            Find your configured placement ID's and names for each domain here.
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.wrapper} spacing={1} direction={'column'}>
        <List disablePadding component={GridIContainer}>
          {domains.map((domain, index) => (
            <>
              <InventoryItem
                index={index}
                domain={domain.title}
                checkedDomain={checked[domain.id]}
                open={open}
                setOpen={setOpen}
                forceOpen={domains.length === 1 && checked[domain.id]?.length > 0}
              />
            </>
          ))}
        </List>
      </Grid>
    </Container>
  );
});
